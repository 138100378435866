import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Moment from "react-moment"
import Layout from "../../components/layout"
import ServiceComponent from "./../../components/services/articles";
import Categories from "./../../components/services/categories";
import Markdown from "react-markdown"

export const query = graphql`
query Category($slug: String!) {
  services: allStrapiServices(filter: {categories: {elemMatch: {slug: {eq: $slug}}}}) {
    edges {
      node {
        strapiId
        slug
        title
        image {
          url
        }
        categories {
          slug
          title
        }
        duration
        description
        price
        product_type
        sections {
          content
          description
          title
          id
        }
      }
    }
  }
  category: strapiCategories(slug: {eq: $slug}) {
    title
  }
}
`;

const Category = ({ data }) => {
  const services = data.services.edges;
  const category = data.category.title;
  const seo = {
    metaTitle: category,
    metaDescription: `All ${category} articles`,
  };

  return (
    <Layout seo={seo}>
      <div className="lg:mx-14 px-4 sm:px-6">
        <div className="flex flex-col gap-12 py-12">
          <div>
            <p className="lg:text-6xl text-small font-semibold">Complex ideas made simple.</p>
          </div>
          <Categories />
        </div>
      </div>
      <div className="rounded-b-2xl lg:rounded-b-3xl" style={{boxShadow: '0px 0px 15px 3px #0000001A'}}>
        <div className="lg:mx-14 px-4 sm:px-6 py-12">
            <ServiceComponent articles={services} />
        </div>
      </div>
    </Layout>
  )
}

export default Category